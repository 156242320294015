<!-- Created by henian.xu on 2018/9/10. -->

<template>
    <page>
        <el-row>
            <el-col :span="12">
                <draggable v-model="myArray">
                    <transition-group>
                        <div
                            class="drag-item"
                            v-for="element in myArray"
                            :key="element.id"
                        >
                            {{ element.name }}
                        </div>
                    </transition-group>
                </draggable>
            </el-col>
            <el-col :span="12">
                <pre>{{ myArray }}</pre>
            </el-col>
        </el-row>

        <el-button @click="onDecorationJson">
            onDecorationJson
        </el-button>
        <PageDecorator
            ref="pageDecorator"
            :json="json"
        />
    </page>
</template>

<script>
export default {
    name: 'BaiduMapPage',
    data: function() {
        return {
            uploadList: [
                {
                    name: '97e957bb845244418625b09aa68a9ef1.png',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/97e957bb845244418625b09aa68a9ef1.png',
                },
                {
                    name: 'ffa69b55e1924c519b1fda87e8477b7d.jpg',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/ffa69b55e1924c519b1fda87e8477b7d.jpg',
                },
                {
                    name: '31f6e6efec034e4697baa94f6242b933.jpg',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/31f6e6efec034e4697baa94f6242b933.jpg',
                },
                {
                    name: 'bfe0ce29a9db476b8a097d3d56100762.jpg',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/bfe0ce29a9db476b8a097d3d56100762.jpg',
                },
                {
                    name: '4bb238d7996e4c2b8313059140e974c7.jpg',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/4bb238d7996e4c2b8313059140e974c7.jpg',
                },
                {
                    name: 'a3e23739f07149c4a251a8e585a3741a.jpg',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/a3e23739f07149c4a251a8e585a3741a.jpg',
                },
            ],
            myArray: [
                {
                    id: 1,
                    name: 'vue',
                },
                {
                    id: 2,
                    name: 'react',
                },
                {
                    id: 3,
                    name: 'angular',
                },
            ],
            json:
                '[{"componentType":"Divider","componentMargin":0,"props":{"label":"1111"}},{"componentType":"Divider","componentMargin":0,"props":{"label":"222"}},{"componentType":"Divider","componentMargin":0,"props":{"label":"333"}},{"componentType":"Ad","componentMargin":0,"props":{"margin":0,"type":"2","delay":3000,"data":[{"src":"http://192.168.1.152:4333/upload/shop/ECCBC87E4B5CE2FE28308FD9F2A7BAF3/shopDecoration/c691b17f968a44c6b804cfac7c84a897.jpg","href":"","alt":""},{"src":"http://192.168.1.152:4333/upload/shop/ECCBC87E4B5CE2FE28308FD9F2A7BAF3/shopDecoration/d4ab514f3f0c4e7c89452fa2309b1b48.png","href":"","alt":""}]}},{"componentType":"Page","props":{"backgroundColor":"#f8f9fa","title":"页面标题"}}]',
        };
    },
    methods: {
        onDecorationJson() {
            console.log(this.$refs['pageDecorator'].getDecorationJson());
        },
    },
};
</script>

<style lang="scss" scoped>
pre {
    padding: $padding;
    background-color: $gray8;
    color: #fff;
    overflow: auto;
}
.drag-item {
    padding: $padding;
    background-color: $gray2;
    + .drag-item {
        margin-top: $margin;
    }
}
</style>
