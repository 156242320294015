export default `LODOP.PRINT_INIT("");
LODOP.ADD_PRINT_TEXT(174,29,346,40,"收件人 18659595959 福建省泉州市鲤城区美食街");
LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");
LODOP.SET_PRINT_STYLEA(0,"FontSize",11);
LODOP.SET_PRINT_STYLEA(0,"Bold",1);
LODOP.ADD_PRINT_TEXT(419,9,20,40,"寄\\r\\n件\\r\\n人");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(419,29,345,40,"寄件人 18659595959 福建省泉州市鲤城区美食街");
LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(311,278,92,17,"签名：");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(132,32,234,35,"111222333");
LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");
LODOP.SET_PRINT_STYLEA(0,"FontSize",26);
LODOP.SET_PRINT_STYLEA(0,"Bold",1);
LODOP.ADD_PRINT_TEXT(216,29,237,40,"寄件人 18659595959 福建省泉州市鲤城区美食街");
LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(311,192,82,17,"收件员:");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(459,29,344,40,"收件人 18659595959 福建省泉州市鲤城区美食街");
LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(256,7,70,17,"件数：10");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_BARCODE(61,31,215,40,"128C","123456");
LODOP.SET_PRINT_STYLEA(0,"ShowBarText",0);
LODOP.ADD_PRINT_BARCODE(349,173,205,40,"128C","123456");
LODOP.SET_PRINT_STYLEA(0,"ShowBarText",0);
LODOP.ADD_PRINT_LINE(252,1,253,446,2,1);
LODOP.ADD_PRINT_LINE(170,0,171,439,2,1);
LODOP.ADD_PRINT_LINE(454,4,455,440,2,1);
LODOP.ADD_PRINT_LINE(415,3,416,447,2,1);
LODOP.ADD_PRINT_LINE(546,24,416,25,2,1);
LODOP.ADD_PRINT_TEXT(331,278,116,20,"时间：    月     日");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(322,192,66,17,"寄件日期:");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(281,7,84,17,"实际重量：");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(268,7,110,17,"付款方式：付款方式\\r\\n");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(256,118,126,17,"声明价值：100");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(293,7,54,17,"计费重量：");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(281,118,120,17,"月结账号：123321");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(269,118,105,17,"保价费用：100");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_LINE(492,2,493,437,2,1);
LODOP.ADD_PRINT_LINE(129,264,59,265,2,1);
LODOP.ADD_PRINT_TEXT(6,163,68,50,"99");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",40);
LODOP.SET_PRINT_STYLEA(0,"Bold",1);
LODOP.ADD_PRINT_LINE(342,190,307,191,2,1);
LODOP.ADD_PRINT_LINE(342,275,307,276,2,1);
LODOP.ADD_PRINT_LINE(252,24,132,25,2,1);
LODOP.ADD_PRINT_LINE(56,-1,57,443,2,1);
LODOP.ADD_PRINT_TEXT(550,223,161,17,"电子面单广告1 电子面单广告2");
LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(134,9,20,40,"目\\r\\n的\\r\\n地");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(62,266,110,20,"快递");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
LODOP.SET_PRINT_STYLEA(0,"Alignment",2);
LODOP.ADD_PRINT_IMAGE(349,5,83,28,"(123321)");
LODOP.SET_PRINT_STYLEA(0,"Stretch",2);
LODOP.ADD_PRINT_IMAGE(374,7,85,27,"(123321)");
LODOP.SET_PRINT_STYLEA(0,"Stretch",2);
LODOP.ADD_PRINT_TEXT(293,118,95,17,"费用合计：");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_LINE(212,1,213,438,2,1);
LODOP.ADD_PRINT_TEXT(334,192,100,17,"2019-01-30");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(105,36,185,23,"111112222233333");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
LODOP.SET_PRINT_STYLEA(0,"Bold",1);
LODOP.SET_PRINT_STYLEA(0,"AlignJustify",1);
LODOP.ADD_PRINT_TEXT(396,175,188,20,"111112222233333");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"Bold",1);
LODOP.SET_PRINT_STYLEA(0,"AlignJustify",1);
LODOP.ADD_PRINT_TEXT(100,36,185,20,"111112222233333");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"Bold",1);
LODOP.SET_PRINT_STYLEA(0,"AlignJustify",1);
LODOP.ADD_PRINT_TEXT(115,36,185,20,"111112222233333");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"Bold",1);
LODOP.SET_PRINT_STYLEA(0,"AlignJustify",1);
LODOP.ADD_PRINT_TEXT(385,175,188,20,"111112222233333");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"Bold",1);
LODOP.SET_PRINT_STYLEA(0,"AlignJustify",1);
LODOP.ADD_PRINT_TEXT(102,6,46,20,"1");
LODOP.ADD_PRINT_TEXT(526,9,30,20,"1");
LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");
LODOP.ADD_PRINT_TEXT(174,9,20,40,"收\\r\\n件\\r\\n人");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.SET_PRINT_STYLEA(0,"Bold",1);
LODOP.ADD_PRINT_TEXT(215,9,20,40,"寄\\r\\n件\\r\\n人");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_LINE(84,265,83,436,2,1);
LODOP.ADD_PRINT_LINE(131,0,132,439,2,1);
LODOP.ADD_PRINT_LINE(306,-2,307,443,2,1);
LODOP.ADD_PRINT_LINE(343,24,308,25,2,1);
LODOP.ADD_PRINT_TEXT(310,9,20,36,"托\\r\\n运\\r\\n物");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(311,31,160,35,"{物品名称}");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(458,9,20,40,"收\\r\\n件\\r\\n人");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_LINE(546,-2,547,438,2,1);
LODOP.ADD_PRINT_TEXT(514,312,68,36,"备注");
LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_TEXT(550,7,219,17,"自定义内容");
LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_LINE(252,266,213,267,2,1);
LODOP.ADD_PRINT_TEXT(293,265,115,17,"1-1");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",7);
LODOP.ADD_PRINT_LINE(510,-1,511,439,2,1);
LODOP.ADD_PRINT_TEXT(494,9,20,26,"数量");
LODOP.SET_PRINT_STYLEA(0,"FontName","{字体}");
LODOP.SET_PRINT_STYLEA(0,"FontSize",6);
LODOP.ADD_PRINT_TEXT(497,138,60,15,"托运物");
LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");
LODOP.SET_PRINT_STYLEA(0,"FontSize",8);
LODOP.SET_PRINT_STYLEA(0,"Alignment",2);
LODOP.ADD_PRINT_TEXT(497,311,60,15,"备注");
LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");
LODOP.SET_PRINT_STYLEA(0,"FontSize",8);
LODOP.SET_PRINT_STYLEA(0,"Alignment",2);
LODOP.ADD_PRINT_LINE(547,309,493,310,2,1);
LODOP.ADD_PRINT_TEXT(527,31,278,24,"物品名称");
LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");
LODOP.ADD_PRINT_TEXT(513,31,206,20,"");
LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");
LODOP.SET_PRINT_STYLEA(0,"FontSize",8);
LODOP.PREVIEW();
`;
